import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import RoomIcon from "@mui/icons-material/Room";
import SignpostIcon from "@mui/icons-material/Signpost";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import TrainIcon from "@mui/icons-material/Train";
import { ListItemIcon, TextField, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactElement, useState } from "react";
import { getAutocomplete } from "../api/autocompleteApi";
import { CustomAutocompleteProps } from "../types/customProps";
import { AutocompleteOption } from "../types/customTypes";
import { isStopSuspended } from "../utils/stopUtils";

let autocompleteTimeout: NodeJS.Timeout;

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  label,
  input,
  setInput,
  value,
  setValue,
  setMarkerPosition,
}) => {
  const [autocompleteData, setAutocompleteData] = useState<any[]>([]);

  const theme = useTheme();

  // Sendet Autocomplete-Anfrage wenn letzte Eingabe 300ms her ist
  const handleAutocompleteInput = async (event: any, newInputValue: string) => {
    clearTimeout(autocompleteTimeout);

    autocompleteTimeout = setTimeout(async () => {
      if (event?.type === "change") {
        try {
          const response = await getAutocomplete(newInputValue);
          setAutocompleteData(
            response.map((entry: any) => {
              return {
                id: entry.id,
                label: entry.data,
                lat: entry.lat,
                lon: entry.lon,
                suspendedFrom: entry.tags?.suspendedFrom,
                suspendedTo: entry.tags?.suspendedTo,
                pointType: entry.ptype,
              };
            }),
          );
        } catch (err) {
          console.log(err);
        }
      }
    }, 300);
  };

  let selectedIcon: ReactElement;
  if (value) {
    switch (value.pointType) {
      case "V":
        selectedIcon = <DirectionsCarIcon />;
        break;
      case "P":
        selectedIcon = <RoomIcon />;
        break;
      case "Z":
        selectedIcon = <TrainIcon />;
        break;
      case "W":
        selectedIcon = <SignpostIcon />;
        break;
      default:
        selectedIcon = <StopCircleIcon />;
    }
  }

  return (
    <Autocomplete
      value={value || null}
      onChange={(event: any, newValue: AutocompleteOption | null) => {
        setValue(newValue);
        setMarkerPosition(
          newValue
            ? {
                lat: newValue.lat,
                lng: newValue.lon,
              }
            : null,
        );
        setAutocompleteData([]);
      }}
      onHighlightChange={(event: any, option: AutocompleteOption | null) => {
        // Rendert Marker auf der Karte, wenn über Vorschläge gehovert wird
        if (option) {
          setMarkerPosition({
            lat: option.lat,
            lng: option.lon,
          });
        }
      }}
      ListboxProps={{
        onMouseLeave: () => setMarkerPosition(null),
      }}
      inputValue={input}
      onInputChange={(event, newInputValue) => {
        setInput(newInputValue);
        handleAutocompleteInput(event, newInputValue);
      }}
      options={autocompleteData}
      renderOption={(props, option) => {
        // Passt Farbe der Vorschläge an, wenn Haltestelle gesperrt ist
        let color;
        switch (isStopSuspended(option.suspendedFrom)) {
          case "aktuell":
            color = theme.palette.error.main;
            break;
          case "zukünftig":
            color = theme.palette.warning.main;
            break;
          default:
            color = "inherit";
        }
        let icon;
        switch (option.pointType) {
          case "V":
            icon = <DirectionsCarIcon style={{ color }} />;
            break;
          case "P":
            icon = <RoomIcon style={{ color }} />;
            break;
          case "Z":
            icon = <TrainIcon style={{ color }} />;
            break;
          case "W":
            icon = <SignpostIcon style={{ color }} />;
            break;
          default:
            icon = <StopCircleIcon style={{ color }} />;
        }
        return (
          <li {...props} key={option.id} style={{ color }}>
            <ListItemIcon>{icon}</ListItemIcon>
            {option.label}
          </li>
        );
      }}
      /* getOptionDisabled={(option) => {
        return isStopSuspended(option.suspendedFrom);
      }} */
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{
            shrink: input !== "" || !!value, // Schrumpfen des Labels basierend auf input und value
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {params.InputProps.startAdornment}
                {selectedIcon && (
                  <ListItemIcon style={{ marginLeft: 5, marginRight: -25 }}>
                    {selectedIcon}
                  </ListItemIcon>
                )}
              </>
            ),
            style: {
              color:
                value && isStopSuspended(value.suspendedFrom) === "aktuell"
                  ? theme.palette.error.main
                  : value &&
                      isStopSuspended(value.suspendedFrom) === "zukünftig"
                    ? theme.palette.warning.main
                    : "inherit",
            },
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
