import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import L from "leaflet";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { Marker, Popup, Tooltip, useMapEvent } from "react-leaflet";
import { getGeoJSONFromCoords } from "../api/nominatimApi";
import "../leaflet-darkmode.css";
import { CustomMarkerProps } from "../types/customProps";
import {
  getShortAdress,
  getSuspendedWarning,
  isStopSuspended,
} from "../utils/stopUtils";

const CustomMarker: React.FC<CustomMarkerProps> = ({
  markerType,
  stopInfo,
  position,
  exSecondMarker,
  setPosition,
  setStopInfo,
}) => {
  const theme = useTheme();

  const handleClick = useCallback(
    async (e: any) => {
      if (
        (markerType === "Start" && !position) ||
        (markerType === "Ziel" && !position && exSecondMarker)
      ) {
        setPosition(e.latlng);
        try {
          const response = await getGeoJSONFromCoords(
            e.latlng.lat,
            e.latlng.lng,
          );
          const properties = response.features[0].properties;
          setStopInfo({
            id: null,
            label: getShortAdress(properties),
            lat: e.latlng.lat,
            lon: e.latlng.lng,
            suspendedFrom: null,
            suspendedTo: null,
            pointType: properties.osm_type === "way" ? "W" : "P",
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    [markerType, position, exSecondMarker, setPosition, setStopInfo],
  );

  useMapEvent("click", handleClick);

  const handleDragEnd = useCallback(
    async (e: any) => {
      const newPosition = e.target.getLatLng();
      setPosition(newPosition);
      try {
        const response = await getGeoJSONFromCoords(
          newPosition.lat,
          newPosition.lng,
        );
        const properties = response.features[0].properties;
        setStopInfo({
          id: null,
          label: getShortAdress(properties),
          lat: newPosition.lat,
          lon: newPosition.lng,
          suspendedFrom: null,
          suspendedTo: null,
          pointType: properties.osm_type === "way" ? "W" : "P",
        });
      } catch (err) {
        console.log(err);
      }
    },
    [setPosition, setStopInfo],
  );

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setPosition(null);
      setStopInfo(null);
    },
    [setPosition, setStopInfo],
  );

  const markerIcon = useMemo(() => {
    let iconColor = "blue";
    if (stopInfo && isStopSuspended(stopInfo.suspendedFrom) === "aktuell") {
      iconColor = "error";
    } else if (
      stopInfo &&
      isStopSuspended(stopInfo.suspendedFrom) === "zukünftig"
    ) {
      iconColor = "warning";
    }

    return new L.Icon({
      iconUrl: require(`../img/icon_default_${iconColor}.png`),
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
      tooltipAnchor: [-12, -21],
    });
  }, [stopInfo]);

  return (
    <>
      {position && (
        <Marker
          position={[position.lat, position.lng]}
          icon={markerIcon}
          draggable
          zIndexOffset={1}
          eventHandlers={{ dragend: handleDragEnd }}
        >
          <Popup
            className={
              theme.palette.mode === "dark" ? "dark-popup" : "light-popup"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {stopInfo ? (
                <div>
                  <b>{stopInfo.label}</b>
                  <br />
                  {stopInfo.lat.toFixed(7)} | {stopInfo.lon.toFixed(7)}
                  <br />
                  {stopInfo.suspendedFrom &&
                    getSuspendedWarning(
                      stopInfo.suspendedFrom,
                      stopInfo.suspendedTo,
                    )}
                </div>
              ) : (
                <div>Keine Informationen verfügbar</div>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                aria-label="delete"
                size="large"
                color="error"
                onClick={handleDeleteClick}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Popup>
          <Tooltip
            direction="left"
            className={
              theme.palette.mode === "dark" ? "dark-tooltip" : "light-tooltip"
            }
            permanent
          >
            {markerType}
          </Tooltip>
        </Marker>
      )}
    </>
  );
};

export default CustomMarker;
