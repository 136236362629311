import axios from "axios";
import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";

export async function getAutocomplete(search: string) {
  const params = {
    headers: {
      accept: "application/json",
      "X-API-Key": appConfig.autocompleteApiKey,
    },
    params: {
      search: search,
      center: "51.3714,12.3720",
      profile: appConfig.city === CityEnum.Halle ? "movemix" : "flexa",
    },
  };
  const response = await axios.get(
    appConfig.autocompleteUrl + "/search/",
    params,
  );

  return response.data;
}
