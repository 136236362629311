import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import HailIcon from "@mui/icons-material/Hail";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import { Button, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { cancelBookingRequest } from "../api/bookingManagerApi";
import { DetailsModalProps } from "../types/customProps";
import Countdown from "./Countdown";
import DetailsTable from "./DetailsTable";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  setDialogOpen,
  originAdress,
  destinationAdress,
  tableData,
  offerExpireTime,
}) => {
  const theme = useTheme();

  const [timeLeft, setTimeLeft] = React.useState<number>(offerExpireTime - 2);
  const [requestAccepted, setRequestAccepted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (open) {
      setTimeLeft(offerExpireTime - 2);
    }
  }, [open, offerExpireTime]);

  const handleCloseButtonClick = React.useCallback(() => {
    if (tableData?.rejectionReason === null && !requestAccepted) {
      cancelBookingRequest(
        tableData.requestId,
        tableData.itineraries[0].itineraryId,
      ).catch((err) => {
        console.log(err);
      });
    }
    setDialogOpen(false);
  }, [tableData, requestAccepted, setDialogOpen]);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      onClose={handleCloseButtonClick}
      PaperProps={{
        style: { maxHeight: "none" },
      }}
    >
      <DialogTitle>Buchungsanfrage</DialogTitle>
      <DialogContent>
        <Box
          component="main"
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            paddingBottom: 2,
          }}
        >
          <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Typography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ verticalAlign: "middle", display: "inline-flex" }}
                      >
                        <HailIcon />
                        {"Start:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography fontSize="18px">{originAdress}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ verticalAlign: "middle", display: "inline-flex" }}
                      >
                        <FlagIcon />
                        {"Ziel:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography fontSize="18px">
                        {destinationAdress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={2}>
                <Paper sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                  {tableData?.rejectionReason === null ? (
                    <Countdown timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
                  ) : (
                    <Typography fontSize="53px" component="h1" variant="h3">
                      <HourglassDisabledIcon fontSize="large" />
                    </Typography>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  {tableData?.rejectionReason && (
                    <Typography
                      fontSize="24px"
                      color={theme.palette.warning.main}
                      fontWeight="bold"
                      align="center"
                      marginBottom={2}
                    >
                      {tableData?.rejectionReasonString}
                    </Typography>
                  )}
                  {tableData?.itineraries ? (
                    <DetailsTable
                      requestId={tableData.requestId}
                      timeLeft={timeLeft}
                      data={tableData?.itineraries}
                      flexaAvailible={!tableData.rejectionReason}
                      requestAccepted={requestAccepted}
                      setRequestAccepted={setRequestAccepted}
                    />
                  ) : (
                    <Typography
                      fontSize="24px"
                      color={theme.palette.error.main}
                      fontWeight="bold"
                      align="center"
                      marginBottom={2}
                    >
                      {"Es konnten keine Verbindungen ermittelt werden."}
                    </Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button endIcon={<CloseIcon />} onClick={handleCloseButtonClick}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsModal;
