import BadgeIcon from "@mui/icons-material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FlagIcon from "@mui/icons-material/Flag";
import HailIcon from "@mui/icons-material/Hail";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhoneIcon from "@mui/icons-material/Phone";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cancelBookingRequest } from "../api/bookingManagerApi";
import { CurrentTableProps } from "../types/customProps";
import { BookingSortable, HeadCell } from "../types/customTypes";
import { getCarStrings } from "../utils/bookingUtils";
import { getComparator } from "../utils/sortUtils";
import LegsTable from "./LegsTable";
import SortableTableHead from "./SortableTableHead";
import TableSkeleton from "./TableSkeleton";

type Order = "asc" | "desc";
const carName = getCarStrings().carName;

const headCells: readonly HeadCell[] = [
  { id: "userName", label: "Name", icon: <BadgeIcon /> },
  { id: "phoneNr", label: "Telefon", icon: <PhoneIcon /> },
  { id: "time", label: "Uhrzeit", icon: <ScheduleIcon /> },
  { id: "originName", label: "Von", icon: <HailIcon /> },
  { id: "destName", label: "Nach", icon: <FlagIcon /> },
  { id: "flexaName", label: carName, icon: <DirectionsCarIcon /> },
];

const CurrentTable: React.FC<CurrentTableProps> = ({ data, loading }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof BookingSortable>("time");

  // States für die Storno-Buttons
  const [pendingCancelRequestId, setPendingCancelRequestId] = useState<
    string | null
  >(null);
  const [cancelledRequestId, setCancelledRequestId] = useState<string | null>(
    null,
  );
  const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);

  // Ausgeklappte Einträge einklappen wenn Daten sich ändern (z.B. bei Suche)
  useEffect(() => {
    setExpandedRow(null);
  }, [data, orderBy, order]);

  const handleRowClick = useCallback(
    (index: number) => {
      setExpandedRow(expandedRow === index ? null : index);
    },
    [expandedRow],
  );

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof BookingSortable) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const sortedRows = useMemo(
    () => data.slice().sort(getComparator(order, orderBy)),
    [data, order, orderBy],
  );

  const handleCancelBookingClick = useCallback(
    async (e: React.MouseEvent, requestId: string, itineraryId: string) => {
      e.stopPropagation();

      if (
        pendingCancelRequestId !== requestId &&
        cancelledRequestId !== requestId
      ) {
        setPendingCancelRequestId(requestId);
      } else if (
        pendingCancelRequestId === requestId &&
        cancelledRequestId !== requestId
      ) {
        try {
          await cancelBookingRequest(requestId, itineraryId);
          setCancelSuccess(true);
          setCancelledRequestId(requestId);
        } catch (err) {
          setCancelSuccess(false);
        } finally {
          setPendingCancelRequestId(null);
        }
      }
    },
    [pendingCancelRequestId, cancelledRequestId],
  );

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
          addEndPlaceholder={true}
        />
        <TableBody>
          {loading ? (
            <TableRow
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "inherit"
                    : theme.palette.grey[200],
              }}
            >
              <TableCell colSpan={8}>
                <TableSkeleton />
              </TableCell>
            </TableRow>
          ) : sortedRows.length === 0 ? (
            <TableRow
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "inherit"
                    : theme.palette.grey[200],
              }}
            >
              <TableCell colSpan={8}>
                <Stack sx={{ width: "100%", alignItems: "center" }}>
                  <Typography
                    fontSize="18px"
                    fontWeight="bold"
                    color={(theme) => theme.palette.warning.main}
                  >
                    Keine Einträge vorhanden!
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            sortedRows.map((row, index) => (
              <React.Fragment key={row.requestId}>
                <TableRow
                  hover
                  key={index}
                  onClick={() => handleRowClick(index)}
                >
                  <TableCell>
                    <IconButton aria-label="expand row" size="small">
                      {expandedRow === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">{row.userName}</TableCell>
                  <TableCell align="center">{row.phoneNr}</TableCell>
                  <TableCell align="center">{row.time}</TableCell>
                  <TableCell align="center">{row.originName}</TableCell>
                  <TableCell align="center">{row.destName}</TableCell>
                  <TableCell align="center">{row.flexaName}</TableCell>
                  <TableCell sx={{ width: 10 }}>
                    <Button
                      variant="contained"
                      disabled={cancelledRequestId === row.requestId}
                      color={
                        pendingCancelRequestId === row.requestId
                          ? "error"
                          : "primary"
                      }
                      sx={{
                        "&:disabled": {
                          color: cancelSuccess
                            ? (theme) => theme.palette.success.main
                            : (theme) => theme.palette.error.main,
                        },
                      }}
                      onClick={(e) =>
                        handleCancelBookingClick(
                          e,
                          row.requestId,
                          row.itineraryId,
                        )
                      }
                    >
                      {pendingCancelRequestId === row.requestId
                        ? "Sicher?"
                        : cancelledRequestId === row.requestId && cancelSuccess
                          ? "Storniert"
                          : cancelledRequestId === row.requestId &&
                              !cancelSuccess
                            ? "Error"
                            : "Storno"}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                  >
                    <Collapse
                      in={expandedRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <LegsTable data={row.legs} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CurrentTable;
