import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { getRequests } from "../api/bookingManagerApi";
import CurrentTable from "../components/CurrentTable";
import CustomSelectMenu from "../components/CustomSelectMenu";
import { Booking } from "../types/customTypes";
import { getCarStrings } from "../utils/bookingUtils";
import { getTodayAndTomorrow } from "../utils/dateUtils";
import { mapActiveBookingsData } from "../utils/mappingUtils";

const maxEntries = 1000; //Die Maximalanzahl an Buchungen, die vom bookingManager zurückgegeben werden

// Aktuelle Fahrten
function CurrentPage() {
  const [tableData, setTableData] = useState<Booking[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Booking[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [searchColumns, setSearchColumns] = useState<string[]>([]);

  const carName = getCarStrings().carName;

  const fetchData = async () => {
    try {
      const [todayISO, tomorrowISO] = getTodayAndTomorrow();
      const response = await getRequests(
        "ACTIVE",
        maxEntries,
        0,
        todayISO,
        tomorrowISO,
      );
      setTableData(mapActiveBookingsData(response));
      setDataLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Abfrage der aktiven Buchungen
  useEffect(() => {
    fetchData();
  }, []);

  const columns = ["Name", "Telefon", "Uhrzeit", "Von", "Nach", carName];

  // Filtere Ergebnisse bei Eingabe eines Suchbegriffs
  useEffect(() => {
    const labelToIdMap: { [key: string]: string } = {
      Name: "userName",
      Telefon: "phoneNr",
      Uhrzeit: "time",
      Von: "originName",
      Nach: "destName",
      [carName]: "flexaName",
    };

    const getSearchColumnIds = () => {
      if (searchColumns.length > 0) {
        return searchColumns.map((label) => labelToIdMap[label]);
      } else {
        return Object.values(labelToIdMap);
      }
    };

    const filterData = () => {
      const searchColumnIds = getSearchColumnIds();

      setFilteredTableData(
        tableData.filter((item) =>
          searchColumnIds.some((id) => {
            const value = item[id as keyof Booking];
            if (value === undefined || value === null) return false;
            if (typeof value === "string") {
              return value.toLowerCase().includes(searchInput.toLowerCase());
            }
            return false;
          }),
        ),
      );
    };

    filterData();
  }, [searchInput, tableData, searchColumns, carName]);

  const handleClearSearchInput = () => {
    setSearchInput("");
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 3, mb: 1 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              maxHeight: "calc(100vh - 100px)",
            }}
          >
            <Grid container mb={3}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Typography fontSize="28px" fontWeight="bold" align={"center"}>
                  Aktuelle Fahrten
                </Typography>
              </Grid>

              <Grid item xs={2} sx={{ display: "flex" }}>
                <TextField
                  label="Suche..."
                  variant="outlined"
                  sx={{
                    width: "100%",
                    maxWidth: "600px",
                    minWidth: "100px",
                    margin: 0.5,
                  }}
                  value={searchInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: searchInput && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear search input"
                          onClick={handleClearSearchInput}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <CustomSelectMenu
                  searchColumns={searchColumns}
                  setSearchColumns={setSearchColumns}
                  columns={columns}
                />
              </Grid>
            </Grid>
            {tableData.length >= maxEntries ? (
              <Typography
                fontSize="18px"
                marginLeft={1}
                marginBottom={1}
                fontWeight="bold"
                color={(theme) => theme.palette.error.main}
              >
                Achtung: Es konnten nicht alle passenden Buchungen dargestellt
                werden, da die Maximalanzahl bei {maxEntries} liegt.
              </Typography>
            ) : null}
            <CurrentTable
              data={searchInput ? filteredTableData : tableData}
              loading={dataLoading}
            ></CurrentTable>
            <Stack sx={{ width: "100%", mt: 1 }}>
              <Typography
                sx={{
                  opacity: 0.5,
                }}
              >
                Zeige {filteredTableData.length} von {tableData.length}{" "}
                Einträgen
              </Typography>
            </Stack>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default CurrentPage;
