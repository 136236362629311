import { Booking, DetailsTableData } from "../types/customTypes";
import {
  findCancelCause,
  findCancelTime,
  findCancelledBy,
  findFlexaName,
  getRequestState,
} from "./bookingUtils";
import { formatDateTime } from "./dateUtils";

export const mapRequestData = (response: any): DetailsTableData => {
  return {
    requestId: response.data.requestId,
    rejectionReason: response.data.rejectionReason,
    rejectionReasonString: response.data.rejectionReasonString,
    itineraries: response.data.itineraries.map((itinerary: any) => ({
      itineraryId: itinerary.itineraryId,
      startDate: new Date(itinerary.startTime).toLocaleDateString("de-DE"),
      duration: itinerary.duration,
      startTimeHHMM: itinerary.startTimeHHMM,
      endTimeHHMM: itinerary.endTimeHHMM,
      transfers: itinerary.transfers,
      legs: itinerary.legs.map((leg: any) => ({
        startTimeHHMM: leg.startTimeHHMM,
        endTimeHHMM: leg.endTimeHHMM,
        distance: leg.distance,
        duration: leg.duration,
        mode: leg.mode,
        route: leg.routeLongName,
        routeColor: leg.routeColor,
        from: leg.from.name,
        to: leg.to.name,
        headsign: leg.headsign,
        passengers: response.data.requestedPassengers.passengers,
        wheelchairs: response.data.requestedPassengers.wheelchairs,
        strollers: response.data.requestedPassengers.strollers,
        childSeats: response.data.requestedPassengers.childSeats,
        babySeats: response.data.requestedPassengers.babySeats,
        flexaName: leg.flexaProperties?.vehicleName,
        departureLowerBound:
          leg.flexaProperties?.promisedDepartureTimeWindow.lowerBound,
        departureUpperBound:
          leg.flexaProperties?.promisedDepartureTimeWindow.upperBound,
        arrivalLowerBound:
          leg.flexaProperties?.promisedArrivalTimeWindow.lowerBound,
        arrivalUpperBound:
          leg.flexaProperties?.promisedArrivalTimeWindow.upperBound,
      })),
    })),
  };
};

export const mapActiveBookingsData = (response: any): Booking[] => {
  const bookings = response.data.map((entry: any) => ({
    requestId: entry.requestId,
    itineraryId: entry.itineraries[0]?.itineraryId,
    userName: entry.client.displayName,
    phoneNr: entry.client.phoneNumber,
    time: formatDateTime(entry.requestedTime),
    originName: entry.requestedOrigin.name,
    destName: entry.requestedDestination.name,
    flexaName:
      entry.itineraries[0]?.legs && entry.itineraries[0]?.legs[0]
        ? (entry.itineraries[0]?.legs[0].flexaProperties?.vehicleName ??
          "unbekannt")
        : entry.itineraries[0]?.legs && entry.itineraries[0]?.legs[1]
          ? (entry.itineraries[0]?.legs[1].flexaProperties?.vehicleName ??
            "unbekannt")
          : "unbekannt",
    legs: entry.itineraries[0]?.legs?.map((leg: any) => ({
      startTimeHHMM: leg.startTimeHHMM,
      endTimeHHMM: leg.endTimeHHMM,
      distance: leg.distance,
      duration: leg.duration,
      mode: leg.mode,
      route: leg.routeLongName,
      routeColor: leg.routeColor,
      from: leg.from.name,
      to: leg.to.name,
      headsign: leg.headsign,
      passengers: entry.requestedPassengers.passengers,
      wheelchairs: entry.requestedPassengers.wheelchairs,
      strollers: entry.requestedPassengers.strollers,
      childSeats: entry.requestedPassengers.childSeats,
      babySeats: entry.requestedPassengers.babySeats,
      flexaName: leg.flexaProperties?.vehicleName,
      departureLowerBound:
        leg.flexaProperties?.promisedDepartureTimeWindow.lowerBound,
      departureUpperBound:
        leg.flexaProperties?.promisedDepartureTimeWindow.upperBound,
      arrivalLowerBound:
        leg.flexaProperties?.promisedArrivalTimeWindow.lowerBound,
      arrivalUpperBound:
        leg.flexaProperties?.promisedArrivalTimeWindow.upperBound,
    })),
  }));

  return bookings;
};

export const mapAllBookingsData = (response: any): Booking[] => {
  const bookings = response.data.map((entry: any) => ({
    requestId: entry.requestId,
    itineraryId: entry.itineraries[0]?.itineraryId,
    userName: entry.client.displayName,
    phoneNr: entry.client.phoneNumber,
    time: formatDateTime(entry.requestedTime),
    originName: entry.requestedOrigin.name,
    destName: entry.requestedDestination.name,
    flexaName: findFlexaName(entry.itineraries[0]?.legs),
    bookedBy: entry.client.platform,
    state: getRequestState(entry.itineraries[0]?.legs),
    cancelTime: findCancelTime(entry.itineraries[0]?.legs),
    cancelledBy: findCancelledBy(entry.itineraries[0]?.legs),
    cancelCause: findCancelCause(entry.itineraries[0]?.legs),
  }));
  return bookings;
};
