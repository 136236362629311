import { CityEnum } from "../consts/CityEnum";

type AppConfigType = {
  city: CityEnum;
  autocompleteUrl: string;
  autocompleteApiKey: string;
  bookingManagerUrl: string;
  bookingManagerToken: string;
  dbServicesUrl: string;
};

export const appConfig: AppConfigType = {
  city: getCityOrDefault("REACT_APP_CITY", CityEnum.Leipzig),
  autocompleteUrl: getRequiredEnv("REACT_APP_AUTOCOMPLETE_URL"),
  autocompleteApiKey: getRequiredEnv("REACT_APP_AUTOCOMPLETE_API_KEY"),
  bookingManagerUrl: getRequiredEnv("REACT_APP_BOOKING_MANAGER_URL"),
  bookingManagerToken: getRequiredEnv("REACT_APP_BOOKING_MANAGER_TOKEN"),
  dbServicesUrl: getRequiredEnv("REACT_APP_DB_SERVICES_URL"),
};

function getRequiredEnv(varName: string): string {
  const envVar = process.env[varName];
  if (!envVar) {
    throw Error(`Please provide ${varName} as environment variable.`);
  }
  return envVar;
}

function getCityOrDefault(varName: string, defaultCity: CityEnum) {
  const envVar = process.env[varName];
  if (envVar && !Object.values(CityEnum).includes(envVar as CityEnum)) {
    throw Error(
      `Please provide a valid ${varName} as environment variable. Allowed values are: ` +
        Object.values(CityEnum).join(", "),
    );
  }
  return (envVar as CityEnum) || defaultCity;
}
