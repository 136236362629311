import axios from "axios";
import { appConfig } from "../config/appConfig";
import {
  UserAuthenticateDto,
  maxNumbersPassengerInfo,
} from "../types/customTypes";

const client = axios.create({
  baseURL: appConfig.dbServicesUrl,
});

export async function getVirtualStops() {
  const response = await client.get("/stops/");

  return response.data;
}

export async function authenticateUser(data: UserAuthenticateDto) {
  const response = await client.post("/users/authenticate", data);

  return response.data;
}

export async function getConfig() {
  const response = await client.get("/config");
  const offerExpireTime = response.data.offerExpireTimeCallcenter.value;
  const passengerInfo: maxNumbersPassengerInfo = {
    maxNumberPassengers: response.data.maxNumberPassengers.value,
    maxNumberWheelchairs: response.data.maxNumberWheelchairs.value,
    maxNumberBabyseats: response.data.maxNumberBabyseats.value,
    maxNumberChildseats: response.data.maxNumberChildseats.value,
    maxNumberStrollers: response.data.maxNumberStrollers.value,
  };

  return { offerExpireTime, passengerInfo };
}
