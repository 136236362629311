import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";
import flexaLogo from "../img/Flexa_rgb.png";
import movemixLogo from "../img/movemix_banner.png";

// Start- und Fallback-Seite bei nicht existierender URL
function LandingPage() {
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {appConfig.city === CityEnum.Halle ? (
          <img src={movemixLogo} alt="Movemix Logo" height={195} width={850} />
        ) : (
          <img src={flexaLogo} alt="Flexa Logo" height={160} width={530} />
        )}

        <Typography
          component="h1"
          fontWeight="bold"
          variant="h1"
          color="inherit"
          noWrap
        >
          Callcenter
        </Typography>
        <Link to="/map">
          <Button size="large" variant="contained" sx={{ mt: 10 }}>
            Fahrt buchen
          </Button>
        </Link>
      </Box>
    </>
  );
}

export default LandingPage;
