import BadgeIcon from "@mui/icons-material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FlagIcon from "@mui/icons-material/Flag";
import HailIcon from "@mui/icons-material/Hail";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhoneIcon from "@mui/icons-material/Phone";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Collapse, IconButton, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { HistoryTableProps } from "../types/customProps";
import { BookingSortable, HeadCell } from "../types/customTypes";
import { getCarStrings } from "../utils/bookingUtils";
import { PlatformLabel, StateLabel } from "../utils/infoUtils";
import { getComparator } from "../utils/sortUtils";
import CancelTable from "./CancelTable";
import SortableTableHead from "./SortableTableHead";
import TableSkeleton from "./TableSkeleton";

type Order = "asc" | "desc";
const carName = getCarStrings().carName;

const headCells: readonly HeadCell[] = [
  {
    id: "userName",
    label: "Name",
    icon: <BadgeIcon />,
  },
  {
    id: "phoneNr",
    label: "Telefon",
    icon: <PhoneIcon />,
  },
  {
    id: "time",
    label: "Uhrzeit",
    icon: <ScheduleIcon />,
  },
  {
    id: "originName",
    label: "Von",
    icon: <HailIcon />,
  },
  {
    id: "destName",
    label: "Nach",
    icon: <FlagIcon />,
  },
  {
    id: "bookedBy",
    label: "Buchung",
    icon: <PostAddIcon />,
  },
  {
    id: "flexaName",
    label: carName,
    icon: <DirectionsCarIcon />,
  },
  {
    id: "state",
    label: "Status",
    icon: <InfoIcon />,
  },
];

const HistoryTable: React.FC<HistoryTableProps> = ({ data, loading }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof BookingSortable>("time");

  // Ausgeklappte Einträge einklappen wenn Daten sich ändern (z.B. bei Suche)
  useEffect(() => {
    setExpandedRow(null);
  }, [data, orderBy, order]);

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BookingSortable,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = data.slice().sort(getComparator(order, orderBy));

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
          addEndPlaceholder={false}
        />
        <TableBody>
          {loading ? (
            <TableRow
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "inherit"
                    : theme.palette.grey[200],
              }}
            >
              <TableCell colSpan={9}>
                <TableSkeleton />
              </TableCell>
            </TableRow>
          ) : sortedRows.length === 0 ? (
            <TableRow
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "inherit"
                    : theme.palette.grey[200],
              }}
            >
              <TableCell colSpan={9}>
                <Stack sx={{ width: "100%", alignItems: "center" }}>
                  <Typography
                    fontSize="18px"
                    fontWeight="bold"
                    color={(theme) => theme.palette.warning.main}
                  >
                    Keine Einträge vorhanden!
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            sortedRows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  key={index}
                  hover
                  onClick={
                    row.state === "storniert"
                      ? () => handleRowClick(index)
                      : undefined
                  }
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? "inherit"
                        : theme.palette.grey[200],
                  }}
                >
                  <TableCell>
                    {row.state === "storniert" ? (
                      <IconButton aria-label="expand row" size="small">
                        {expandedRow === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    ) : null}
                  </TableCell>
                  <TableCell align="center">{row.userName}</TableCell>
                  <TableCell align="center">{row.phoneNr}</TableCell>
                  <TableCell align="center">{row.time}</TableCell>
                  <TableCell align="center">{row.originName}</TableCell>
                  <TableCell align="center">{row.destName}</TableCell>
                  <TableCell align="center">
                    <PlatformLabel platform={row.bookedBy} />
                  </TableCell>
                  <TableCell align="center">{row.flexaName}</TableCell>
                  <TableCell align="center">
                    <StateLabel state={row.state} />
                  </TableCell>
                </TableRow>
                {row.cancelTime && row.cancelledBy && row.cancelCause ? (
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                      }}
                      colSpan={9}
                    >
                      <Collapse
                        in={expandedRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CancelTable
                          time={row.cancelTime}
                          by={row.cancelledBy}
                          cause={row.cancelCause}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryTable;
