import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";
import { ItineraryLeg } from "../types/customTypes";

export const getCarStrings = () => {
  const carName = appConfig.city === CityEnum.Halle ? "Shuttle" : "Flexa";
  const carIdentifier =
    appConfig.city === CityEnum.Halle ? "MOVEMIXSHUTTLE" : "FLEXA";

  return { carName, carIdentifier };
};

const { carName, carIdentifier } = getCarStrings();

export const uniqueModesAsString = (legs: ItineraryLeg[]): string => {
  // Ein Set verwenden, um die einzigartigen Werte für 'mode' zu speichern
  const uniqueModesSet = new Set<string>();

  // Durch jedes Element im Array iterieren und die 'mode'-Werte zum Set hinzufügen
  legs.forEach((leg) => {
    if (leg.mode !== "WALK" && leg.mode !== "WAIT") {
      uniqueModesSet.add(convertToLowerCaseExceptFirst(leg.mode));
    }
  });

  // Das Set in einen Array umwandeln und dann zu einem String joinen
  return Array.from(uniqueModesSet).join(", ");
};

export const getNonFlexaInfos = (leg: ItineraryLeg): string | null => {
  if (leg.mode === "WAIT") return null;
  if (leg.mode === "WALK") return leg.distance.toString() + "m Fußweg";
  if (leg.mode !== carIdentifier && leg.headsign)
    return "Richtung " + leg.headsign;
  return null;
};

export const getLegMode = (route: string | null, mode: string): string => {
  return route
    ? route === carIdentifier
      ? carName
      : route
    : mode === "WALK"
      ? "Zu Fuß"
      : mode === "WAIT"
        ? "Warten"
        : mode;
};

export function findFlexaName(array: any[]): string {
  for (const item of array) {
    if (item.flexaProperties && item.flexaProperties.vehicleName !== null) {
      return item.flexaProperties.vehicleName;
    }
  }
  return "unbekannt";
}
export function findCancelTime(array: any[]): string | null {
  for (const item of array) {
    if (
      item.flexaProperties &&
      item.flexaProperties.cancelled &&
      item.flexaProperties.cancelled.date !== null
    ) {
      return item.flexaProperties.cancelled.date;
    }
  }
  return null;
}

export function findCancelledBy(array: any[]): string | null {
  for (const item of array) {
    if (
      item.flexaProperties &&
      item.flexaProperties.cancelled &&
      item.flexaProperties.cancelled.by !== null
    ) {
      const germanCause =
        item.flexaProperties.cancelled.by === "OPERATOR"
          ? "Mitarbeiter"
          : item.flexaProperties.cancelled.by === "DRIVER"
            ? "Fahrer"
            : item.flexaProperties.cancelled.by === "CALLCENTER"
              ? "Callcenter"
              : item.flexaProperties.cancelled.by === "APP"
                ? "Nutzer"
                : item.flexaProperties.cancelled.by === "SYSTEM" ||
                    item.flexaProperties.cancelled.by === "OBSERVER"
                  ? "System"
                  : item.flexaProperties.cancelled.by;
      return germanCause;
    }
  }
  return null;
}

export function findCancelCause(array: any[]): string | null {
  for (const item of array) {
    if (
      item.flexaProperties &&
      item.flexaProperties.cancelled &&
      item.flexaProperties.cancelled.cause !== null
    ) {
      const originalCause = item.flexaProperties.cancelled.cause;
      const germanCause =
        originalCause === "USER_CANCELLED"
          ? "Nutzer hat storniert"
          : originalCause === "PENDING_SHIFT"
            ? "Schicht nicht besetzt"
            : originalCause === "OPERATOR_NOT_PRESENT" ||
                originalCause === "DRIVER_NOT_PRESENT_NOT_CONTACTABLE"
              ? "Kunde war nicht am vereinbarten Ort"
              : originalCause ===
                    "OPERATOR_VEHICLE_NOT_IN_TIME_NOT_CONTACTABLE" ||
                  originalCause ===
                    "OPERATOR_VEHICLE_NOT_IN_TIME_ALTERNATIVE_SOLUTION_FOUND" ||
                  originalCause === "DRIVER_NOT_IN_TIME"
                ? "Fahrzeug hat Verspätung"
                : originalCause ===
                      "OPERATOR_BAD_STREET_SITUATION_NOT_CONTACTABLE" ||
                    originalCause ===
                      "OPERATOR_BAD_STREET_SITUATION_ALTERNATIVE_SOLUTION_FOUND"
                  ? "Fahrzeug kann Abholpunkt wegen Straßenverhältnissen nicht anfahren"
                  : originalCause === "OPERATOR_TECHNICAL_PROBLEM_GENERAL" ||
                      originalCause ===
                        "OPERATOR_TECHNICAL_PROBLEM_NOT_CONTACTABLE" ||
                      originalCause ===
                        "OPERATOR_TECHNICAL_PROBLEM_ALTERNATIVE_SOLUTION_FOUND" ||
                      originalCause === "DRIVER_TECHNICAL_PROBLEM_GENERAL"
                    ? "Fahrzeug hat technisches Problem"
                    : originalCause;
      return germanCause;
    }
  }
  return null;
}

export function existsActualDepartureDate(array: any[]): boolean {
  for (const item of array) {
    if (item.flexaProperties && item.flexaProperties.actualDepartureDate) {
      return true;
    }
  }
  return false;
}

export function existsActualArrivalDate(array: any[]): boolean {
  for (const item of array) {
    if (item.flexaProperties && item.flexaProperties.actualArrivalDate) {
      return true;
    }
  }
  return false;
}

export function getRequestState(array: any[]): string {
  if (findCancelTime(array)) return "storniert";
  if (existsActualDepartureDate(array)) {
    if (existsActualArrivalDate(array)) {
      return "abgeschlossen";
    } else {
      return "begonnen";
    }
  } else {
    return "noch nicht begonnen";
  }
}

export function convertToLowerCaseExceptFirst(input: string): string {
  if (input.length === 0 || input === "IOS") {
    return input;
  }

  const firstChar = input.charAt(0);
  const restOfString = input.slice(1).toLowerCase();

  return firstChar + restOfString;
}

// Nimmt einen Hex-Code (ohne #) und gibt dessen Helligkeit zwischen 0 (schwarz) und 255 (weiß) zurück
export function getBrightnessFromHexColor(hex: string): number {
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return 0.299 * r + 0.587 * g + 0.114 * b;
}
