import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";
import flexaLogo from "../img/Flexa_negativ_rgb.png";
import movemixLogo from "../img/movemix_banner.png";
import { mainListItems, secondaryListItems } from "../listItems";
import { ChildrenProps } from "../types/customProps";
import AppBar from "./AppBar";
import NavBar from "./NavBar";

const PageFrame: React.FC<ChildrenProps> = ({ children }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <AppBar position="fixed" enableColorOnDark open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h5"
            gap={"5px"}
            display={"inline-flex"}
            color="inherit"
            fontWeight={"Medium"}
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {appConfig.city === CityEnum.Halle
              ? "movemix_shuttle Callcenter"
              : "FLEXA - Callcenter"}
            <SupportAgentIcon fontSize="large" />
          </Typography>

          {appConfig.city === CityEnum.Halle ? (
            <img src={movemixLogo} alt="Movemix Logo" height={65} width={283} />
          ) : (
            <img src={flexaLogo} alt="Flexa Logo" height={48} width={106} />
          )}
        </Toolbar>
      </AppBar>
      <NavBar
        variant="permanent"
        open={open}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List
          component="nav"
          sx={{
            flexGrow: 1,
            overflowY: "visible",
          }}
        >
          {mainListItems}
          <Divider sx={{ my: 2 }} />
          {secondaryListItems}
        </List>

        <>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "64px", // Höhe auf die Höhe der anderen Buttons anpassen
            }}
          >
            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <LogoutIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </Box>
        </>
      </NavBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {children /* Seiteninhalt */}
      </Box>
    </Box>
  );
};

export default PageFrame;
