import { Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";

import EmailIcon from "@mui/icons-material/Email";
import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";
// Aktuelle Fahrten
function HelpPage() {
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 3, mb: 1 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: window.innerHeight - 100,
            }}
          >
            <Typography fontSize="28px" fontWeight="bold" mb={5}>
              Hilfe
            </Typography>
            <Typography fontSize="18px" mb={5}>
              Bei Problemen im Umgang mit der Callcenter-Oberfläche, versuchen
              Sie bitte die Website neu zu laden.
            </Typography>
            {appConfig.city === CityEnum.Halle ? (
              <Typography fontSize="18px" mb={5}>
                Bei Fragen zur Handhabung, nutzen Sie bitte die
                Schulungsunterlagen unter „Anleitung“.
              </Typography>
            ) : null}

            <Typography fontSize="18px" mb={2}>
              Wenn sich das Problem damit nicht beheben lässt, wenden Sie sich
              bitte unter Angabe von Details und ggf. Screenshots an
            </Typography>

            <Link
              href={
                appConfig.city === CityEnum.Halle
                  ? "mailto:nico.rudolph@havag.com"
                  : "mailto:meldung.flexa.verkehrsbetriebe@l.de"
              }
              fontSize={20}
              gap={"5px"}
              fontWeight={"bold"}
              display={"inline-flex"}
              mb={5}
            >
              <EmailIcon />
              {appConfig.city === CityEnum.Halle
                ? "nico.rudolph@havag.com"
                : "meldung.flexa.verkehrsbetriebe@l.de"}
            </Link>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default HelpPage;
