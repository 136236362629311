import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

/**
 * Konvertiert ein Datum vom ISO-Format in das Format DD.MM.YYYY.
 * @param {string} isoDate Ein Datum im ISO-Format (YYYY-MM-DDT00:00:00Z).
 * @return {string} Das Datum im Format DD.MM.YYYY.
 */
export const formatDate = (isoDate: string) => {
  if (!isoDate) return "";

  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

/**
 * Konvertiert ein Datum vom ISO-Format in das Format TT.MM., HH:MM.
 * @param {string} isoDate Ein Datum im ISO-Format (YYYY-MM-DDT00:00:00Z).
 * @return {string} Das Datum im Format TT.MM., HH:MM.
 */
export const formatDateTime = (isoDate: string) => {
  if (!isoDate) return "";

  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}. - ${hours}:${minutes}`;
};

/**
 * Konvertiert ein Datum vom ISO-Format in das Format HH:MM.
 * @param {string} isoDate Ein Datum im ISO-Format (YYYY-MM-DDT00:00:00Z).
 * @return {string} Das Datum im Format HH:MM.
 */
export const formatISODateTime = (isoDate: string) => {
  if (!isoDate) return "";

  const date = new Date(isoDate);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

/**
 * Konvertiert eine Anzahl von Sekunden in das Format HH:MM.
 * @param {number} time Anzahl von Sekunden.
 * @return {string} Die Zeit im Format HH:MM.
 */
export const formatTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  const formattedHours = hours < 10 ? "0" + hours : hours.toString();
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();

  return `${formattedHours}:${formattedMinutes}`;
};

/**
 * Konvertiert eine Anzahl von Sekunden in das Format MM:SS.
 * @param {number} time Anzahl von Sekunden.
 * @return {string} Die Zeit im Format MM:SS.
 */
export const formatCountdown = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};

/**
 * Gibt die ISO-Bounds des Tages zurück (Mitternacht bis Mitternacht)
 * @param {dayjs} date Dayjs-Datum des gewünschten Tages
 * @return {string[]} ISO-Strings des eingebenen und folgenden Tages um 00:00 Uhr
 */
export function getDayBoundaries(date: dayjs.Dayjs): [string, string] {
  const dayStart = date.startOf("day").toISOString();
  const nextDayStart = date.add(1, "day").startOf("day").toISOString();
  return [dayStart, nextDayStart];
}

//Wandelt Tag und Zeit im Dayjs-Format in einen UTC-ISO-String um
export function buildISOStringGMT(
  departureDay: dayjs.Dayjs,
  departureTime: dayjs.Dayjs,
  allowPast: boolean,
): string {
  if (!allowPast) {
    // Überprüfe, ob Zeitpunkt in der Vergangenheit liegt. Wenn ja, verwende aktuellen Zeitpunkt
    const departureDateTime = dayjs(
      `${departureDay.format("YYYY-MM-DD")} ${departureTime.format("HH:mm:ss")}`,
    );
    const now = dayjs().add(1, "minute"); // 1 Minute Puffer, um die Laufzeit der Backend-Anfrage zu berücksichtigen
    if (departureDateTime.isBefore(now)) {
      departureDay = now;
      departureTime = now;
    }
  }

  // Kombiniere Datum und Zeit und wandle in UTC um
  const departureDateTime = dayjs(
    `${departureDay.format("YYYY-MM-DD")} ${departureTime.format("HH:mm:ss")}`,
  ).utc();

  // Erstelle den ISO-String
  return departureDateTime.toISOString();
}

// Gibt die ISO-Bounds des heutigen Tages zurück (Mitternacht bis Mitternacht)
export const getTodayAndTomorrow = (): [string, string] => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const todayISOString = today.toISOString().split("T")[0] + "T00:00:00Z";
  const tomorrowISOString = tomorrow.toISOString().split("T")[0] + "T00:00:00Z";

  return [todayISOString, tomorrowISOString];
};
