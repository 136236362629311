import CssBaseline from "@mui/material/CssBaseline";
import { deDE } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import PageFrame from "./components/PageFrame";
import ProtectedRoute from "./components/ProtectedRoute";
import { appConfig } from "./config/appConfig";
import { CityEnum } from "./consts/CityEnum";
import CurrentPage from "./pages/CurrentPage";
import HelpPage from "./pages/HelpPage";
import HistoryPage from "./pages/HistoryPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import ManualPage from "./pages/ManualPage";
import MapPage from "./pages/MapPage";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const leipzigTheme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: prefersDarkMode ? "dark" : "light",
            grey: {
              "100": "#fafafa",
              "900": "#303030",
            },
            primary: {
              main: "#fbc10f",
            },
            secondary: {
              main: "#032c58",
            },
            success: {
              main: "#498b18",
            },
            error: {
              main: "#d3000c",
            },
            info: {
              main: "#032c58",
            },
          },
        },
        deDE,
      ),
    [prefersDarkMode],
  );

  const halleTheme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: prefersDarkMode ? "dark" : "light",
            grey: {
              "100": "#fafafa",
              "900": "#303030",
            },
            primary: {
              main: "#9eabd5",
            },
            secondary: {
              main: "#4b4b4b",
            },
            success: {
              main: "#498b18",
            },
            error: {
              main: "#d3000c",
            },
            info: {
              main: "#032c58",
            },
          },
        },
        deDE,
      ),
    [prefersDarkMode],
  );

  const theme = appConfig.city === CityEnum.Halle ? halleTheme : leipzigTheme;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="*"
            element={
              <PageFrame>
                <Routes>
                  <Route
                    index
                    element={
                      <ProtectedRoute>
                        <MapPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/map"
                    element={
                      <ProtectedRoute>
                        <MapPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/current"
                    element={
                      <ProtectedRoute>
                        <CurrentPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/history"
                    element={
                      <ProtectedRoute>
                        <HistoryPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/help"
                    element={
                      <ProtectedRoute>
                        <HelpPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/manual"
                    element={
                      <ProtectedRoute>
                        <ManualPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <ProtectedRoute>
                        <LandingPage />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </PageFrame>
            }
          />
        </Routes>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
