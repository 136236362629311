import { Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { TransferSchemaProps } from "../types/customProps";
import { formatTime } from "../utils/dateUtils";

const TransferSchema: React.FC<TransferSchemaProps> = ({
  width,
  legs,
  //totalDuration,
}) => {
  const theme = useTheme();
  // Berechnen der Gesamtdauer
  const totalDuration = legs.reduce((acc, leg) => acc + leg.duration, 0);
  // Berechnen der Abschnitte und Punkte
  const sections: JSX.Element[] = [];
  legs.forEach((leg, index) => {
    const sectionWidth = Math.floor(
      (leg.duration / totalDuration) * (width - (legs.length + 1) * 10),
    ); // Zieht für jeden Punkt 10px von der verfügbaren Gesamtlänge ab
    const sectionStyle: React.CSSProperties = {
      display: "inline-block",
      height: "10px",
      borderRadius: "5px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "fff",
      width: sectionWidth,
      backgroundColor: leg.routeColor
        ? `#${leg.routeColor}`
        : theme.palette.mode === "dark"
          ? theme.palette.grey[900]
          : theme.palette.grey[100],
      position: "relative",
    };
    if (index === 0) {
      sections.push(
        <Tooltip
          key={`dot-tooltip-0`}
          title={
            <Typography display={"block"} fontSize={14}>
              <b>{leg.startTimeHHMM}: </b>
              {leg.from}
            </Typography>
          }
          placement="top"
          arrow
        >
          <div
            className="dot"
            style={{
              display: "inline-block",
              left: "100%",
              width: "10px",
              height: "10px",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              borderRadius: "50%",
              zIndex: 1,
            }}
          ></div>
        </Tooltip>,
      );
    }
    sections.push(
      <Tooltip
        key={`line-tooltip-${index}`}
        title={
          <Typography display={"block"} fontSize={14}>
            Dauer: {formatTime(leg.duration)}
            <br />
            {leg.mode === "WALK"
              ? `Weglänge: ${leg.distance}m`
              : leg.mode === "WAIT"
                ? "Warten"
                : `Linie: ${leg.route}`}
            <br />
            {leg.mode !== "WALK" && leg.mode !== "WAIT"
              ? `Richtung: ${leg.headsign}`
              : ""}
          </Typography>
        }
        placement="top"
        arrow
      >
        <div key={`section-${index}`} style={sectionStyle}></div>
      </Tooltip>,
    );
    sections.push(
      <Tooltip
        key={`dot-tooltip-${index + 1}`}
        title={
          <Typography display={"block"} fontSize={14}>
            <b>{leg.endTimeHHMM}: </b>
            {leg.to}
          </Typography>
        }
        placement="top"
        arrow
      >
        <div
          className="dot"
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            borderRadius: "50%",
          }}
        ></div>
      </Tooltip>,
    );
  });

  return <div>{sections}</div>;
};

export default TransferSchema;
