import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { getRequests } from "../api/bookingManagerApi";
import CustomSelectMenu from "../components/CustomSelectMenu";
import HistoryTable from "../components/HistoryTable";
import { Booking } from "../types/customTypes";
import { getCarStrings } from "../utils/bookingUtils";
import { mapAllBookingsData } from "../utils/mappingUtils";

const maxEntries = 1000; //Die Maximalanzahl an Buchungen, die vom bookingManager zurückgegeben werden

// Fahrthistorie
function HistoryPage() {
  const [tableData, setTableData] = useState<Booking[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Booking[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [lowerBound, setLowerBound] = useState<Dayjs | null>(
    dayjs().subtract(12, "hour"),
  );
  const [upperBound, setUpperBound] = useState<Dayjs | null>(dayjs());
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [searchColumns, setSearchColumns] = useState<string[]>([]);

  const carName = getCarStrings().carName;

  const fetchData = useCallback(async () => {
    try {
      if (lowerBound?.isValid() && upperBound?.isValid()) {
        setDataLoading(true);
        const lowerISO = lowerBound.utc().toISOString();
        const upperISO = upperBound.utc().toISOString();
        const response = await getRequests(
          "ALL",
          maxEntries,
          0,
          lowerISO,
          upperISO,
        );
        setTableData(mapAllBookingsData(response));
        setDataLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [lowerBound, upperBound]);

  // Abfrage der Buchungen des ausgewählten Tages
  useEffect(() => {
    fetchData();
  }, [lowerBound, upperBound, fetchData]);

  const columns = [
    "Name",
    "Telefon",
    "Uhrzeit",
    "Von",
    "Nach",
    "Buchung",
    carName,
    "Status",
  ];

  // Filtere Ergebnisse bei Eingabe eines Suchbegriffs
  useEffect(() => {
    const labelToIdMap: { [key: string]: string } = {
      Name: "userName",
      Telefon: "phoneNr",
      Uhrzeit: "time",
      Von: "originName",
      Nach: "destName",
      Buchung: "bookedBy",
      [carName]: "flexaName",
      Status: "state",
    };

    const getSearchColumnIds = () => {
      if (searchColumns.length > 0) {
        return searchColumns.map((label) => labelToIdMap[label]);
      } else {
        return Object.values(labelToIdMap);
      }
    };

    const filterData = () => {
      const searchColumnIds = getSearchColumnIds();

      setFilteredTableData(
        tableData.filter((item) =>
          searchColumnIds.some((id) => {
            const value = item[id as keyof Booking];
            if (value === undefined || value === null) return false;
            if (typeof value === "string") {
              return value.toLowerCase().includes(searchInput.toLowerCase());
            }
            return false;
          }),
        ),
      );
    };

    filterData();
  }, [searchInput, tableData, searchColumns, carName]);

  const handleClearSearchInput = () => {
    setSearchInput("");
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 3, mb: 1 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              maxHeight: "calc(100vh - 100px)",
            }}
          >
            <Grid container mb={3}>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <DateTimePicker
                  label="Von"
                  value={lowerBound}
                  sx={{ maxWidth: "190px", margin: 0.5 }}
                  maxDateTime={upperBound}
                  onChange={(newValue) => setLowerBound(newValue)}
                />
                <DateTimePicker
                  label="Bis"
                  value={upperBound}
                  sx={{ maxWidth: "190px", margin: 0.5 }}
                  minDateTime={lowerBound}
                  maxDate={dayjs().add(7, "days")}
                  onChange={(newValue) => setUpperBound(newValue)}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography fontSize="28px" fontWeight="bold" align={"center"}>
                  Fahrthistorie
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <TextField
                  label="Suche..."
                  variant="outlined"
                  sx={{
                    width: "100%",
                    maxWidth: "600px",
                    minWidth: "100px",
                    margin: 0.5,
                  }}
                  value={searchInput}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: searchInput && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear search input"
                          onClick={handleClearSearchInput}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <CustomSelectMenu
                  searchColumns={searchColumns}
                  setSearchColumns={setSearchColumns}
                  columns={columns}
                />
              </Grid>
            </Grid>
            {lowerBound?.isAfter(upperBound) ? (
              <Typography
                fontSize="18px"
                marginLeft={1}
                marginBottom={1}
                fontWeight="bold"
                color={(theme) => theme.palette.error.main}
              >
                Fehler: Ungültige Zeitspanne.
              </Typography>
            ) : tableData.length >= maxEntries ? (
              <Typography
                fontSize="18px"
                marginLeft={1}
                marginBottom={1}
                fontWeight="bold"
                color={(theme) => theme.palette.error.main}
              >
                Achtung: Es konnten nicht alle passenden Buchungen dargestellt
                werden, da die Maximalanzahl bei {maxEntries} liegt. Verkleinern
                Sie den Zeitraum.
              </Typography>
            ) : null}
            <HistoryTable
              data={searchInput ? filteredTableData : tableData}
              loading={dataLoading}
            ></HistoryTable>
            <Stack sx={{ width: "100%", mt: 1, flexDirection: "row" }}>
              <Typography
                sx={{
                  opacity: 0.5,
                  whiteSpace: "pre",
                }}
              >
                Zeige {filteredTableData.length} von{" "}
              </Typography>
              <Typography
                sx={{
                  opacity: 0.5,
                  whiteSpace: "pre",
                }}
                color={(theme) =>
                  tableData.length >= maxEntries
                    ? theme.palette.error.main
                    : "inherit"
                }
              >
                {tableData.length}
              </Typography>
              <Typography
                sx={{
                  opacity: 0.5,
                  whiteSpace: "pre",
                }}
              >
                {" Einträgen"}
              </Typography>
            </Stack>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default HistoryPage;
