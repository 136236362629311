import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RouteIcon from "@mui/icons-material/Route";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import TimelineIcon from "@mui/icons-material/Timeline";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  Button,
  Collapse,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { acceptBookingRequest } from "../api/bookingManagerApi";
import { DetailsTableProps } from "../types/customProps";
import { uniqueModesAsString } from "../utils/bookingUtils";
import { formatTime } from "../utils/dateUtils";
import LegsTable from "./LegsTable";
import TransferSchema from "./TransferSchema";

const DetailsTable: React.FC<DetailsTableProps> = ({
  requestId,
  timeLeft,
  data,
  flexaAvailible,
  requestAccepted,
  setRequestAccepted,
}) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [failedToAccept, setFailedToAccept] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleRowClick = useCallback(
    (index: number) => {
      setExpandedRow(expandedRow === index ? null : index);
    },
    [expandedRow],
  );

  const handleAcceptBookingClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        const response = await acceptBookingRequest(
          requestId,
          data[0].itineraryId,
        );
        if (response.statusCode === 200) {
          setRequestAccepted(true);
          setTimeout(() => navigate("/current"), 2000);
        } else {
          setFailedToAccept(true);
        }
      } catch (err) {
        console.log(err);
        setFailedToAccept(true);
      }
    },
    [requestId, data, setRequestAccepted, navigate],
  );

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            />
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <EventIcon /> Datum
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <AlarmIcon /> Startzeit
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <AlarmOnIcon /> Ankunftszeit
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                minWidth: 390,
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <RouteIcon /> Umstiegsschema
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <TimelapseIcon /> Dauer
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <TransferWithinAStationIcon /> Umstiege
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="bold"
                sx={{ display: "inline-flex", verticalAlign: "middle" }}
              >
                <TimelineIcon /> Produkte
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[300]
                    : null,
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <React.Fragment key={row.itineraryId}>
              <TableRow hover onClick={() => handleRowClick(index)}>
                <TableCell>
                  <IconButton aria-label="expand row" size="small">
                    {expandedRow === index ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.startDate}
                </TableCell>
                <TableCell align="center">{row.startTimeHHMM}</TableCell>
                <TableCell align="center">{row.endTimeHHMM}</TableCell>
                <TableCell align="center">
                  <TransferSchema
                    width={350}
                    legs={row.legs}
                    totalDuration={row.duration}
                  />
                </TableCell>
                <TableCell align="center">{formatTime(row.duration)}</TableCell>
                <TableCell align="center">{row.transfers || 0}</TableCell>
                <TableCell align="center">
                  {uniqueModesAsString(row.legs)}
                </TableCell>
                <TableCell sx={{ width: 10 }}>
                  {flexaAvailible && index === 0 && (
                    <Button
                      variant="contained"
                      disabled={timeLeft <= 0 || requestAccepted}
                      sx={{
                        borderColor: failedToAccept
                          ? theme.palette.error.main
                          : "inherit",
                        "&:disabled": {
                          color: requestAccepted
                            ? theme.palette.success.main
                            : failedToAccept
                              ? theme.palette.error.main
                              : "inherit",
                        },
                      }}
                      onClick={(e) => handleAcceptBookingClick(e)}
                    >
                      {requestAccepted
                        ? "Gebucht"
                        : failedToAccept
                          ? "Fehler"
                          : "Buchen"}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={9}
                >
                  <Collapse
                    in={expandedRow === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <LegsTable data={row.legs} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsTable;
