import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HistoryIcon from "@mui/icons-material/History";
import MapIcon from "@mui/icons-material/Map";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import * as React from "react";
import NavItem from "./components/NavItem";
import { appConfig } from "./config/appConfig";
import { CityEnum } from "./consts/CityEnum";

// Enthält die Einträge der Navigationsleiste
export const mainListItems = (
  <React.Fragment>
    <NavItem
      to={"/map"}
      defaultIcon={<MapOutlinedIcon fontSize="large" />}
      activeIcon={<MapIcon fontSize="large" />}
      text="Fahrt buchen"
    />
    <NavItem
      to={"/current"}
      defaultIcon={<DirectionsCarOutlinedIcon fontSize="large" />}
      activeIcon={<DirectionsCarIcon fontSize="large" />}
      text="Aktuelle Fahrten"
    />
    <NavItem
      to={"/history"}
      defaultIcon={<HistoryIcon fontSize="large" />}
      activeIcon={<HistoryIcon fontSize="large" />}
      text="Fahrthistorie"
    />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    {appConfig.city === CityEnum.Halle ? (
      <NavItem
        to={"/manual"}
        defaultIcon={<AssignmentOutlinedIcon fontSize="large" />}
        activeIcon={<AssignmentIcon fontSize="large" />}
        text="Anleitung"
      />
    ) : null}

    <NavItem
      to={"/help"}
      defaultIcon={<HelpOutlineIcon fontSize="large" />}
      activeIcon={<HelpIcon fontSize="large" />}
      text="Hilfe"
    />
  </React.Fragment>
);
